<template>
    <div class="phContentFour">
        <img src="http://iv.vu818.com/img/bg3 (1).jpg" alt="" class="pcfBg" />
        <div class="phfBox">
            <!-- 顶部标题 -->
            <div class="phfTopTitle wow animate__animated animate__slideInDown">
                <p class="phfBig1">手机网站≠微站</p>
                <p class="phfBig2">真正的微站是移动互联网的全媒体站</p>
                <p class="phfSmall1">
                    目前的微站99.5%只是手机站。微站是在手机站基础上、生成WEB app，并与微信、微博、二维码的集成。
                </p>
                <p class="phfSmall2">
                    内容同步：数据互通无缝对接。使用手机进行快速信息获取，在线预订、在线支付、在线反馈、在线报名、在线调查。
                </p>
            </div>
            <!-- 底部图片 -->
            <div class="phfBottomImg wow animate__animated animate__slideInUp">
                <!-- <img src="http://iv.vu818.com/img/tp41.png" class="phfImg wow animate__animated animate__slideInUp" /> -->
                <template v-for="el in list">
                    <vy-phone :key="el.id" :element="el"></vy-phone>
                </template>
            </div>
            <!-- 右侧文字 -->
            <div class="phfTextBox1 wow animate__animated animate__slideInRight">
                <p class="phfBig">微信公众平台</p>
                <p class="phfSmall">可实现自由跳转，全面整合微信营销体系。</p>
            </div>
            <div class="phfTextBox2 wow animate__animated animate__slideInRight">
                <p class="phfBig">微博</p>
                <p class="phfSmall">微站可直接访问企业微博，信息互享。</p>
            </div>
        </div>
    </div>
</template>

<script>
import VyPhone from "@/util/VyPhone.vue";
export default {
    components: { VyPhone },
    name: "PhContentFour",
    data() {
        return {
            list: [],
        };
    },
    props: ["phoneData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {},
    watch: {
        async phoneData(newVal) {
            let nums = [];
            let { data } = this.phoneData;
            if (data.length < 2) {
                //  未满2
                nums = data;
            } else {
                for (let i = 0; i < 2; i++) {
                    nums.push(sj(data.length));
                }
                nums.forEach((e, i) => {
                    nums[i] = data[e];
                });
            }
            let { data: list } = await axios.get("/api/project/mobilephoneid", { params: { id: nums.join(",") } });
            this.list = list;
            function sj(range) {
                // 0 ~ range随机数
                let num = Math.floor(Math.random() * range);
                if (nums.findIndex(e => e == num) != -1) return sj(range);
                return num;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.phContentFour {
    // min-width: 1423px;
    width: 100%;
    height: 800px;
    margin: 0 auto;
    margin-top: 100px;
    position: relative;
    overflow: hidden;
    .pcfBg {
        // width: 100%;
        height: 800px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .phfBox {
        // width: 90%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        // 顶部标题
        .phfTopTitle {
            width: 1200px;
            height: 180px;
            margin: 0 auto;
            margin-top: 80px;
            .phfBig1 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
                font-weight: 900;
                text-align: center;
                color: white;
            }
            .phfBig2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
                text-align: center;
                font-weight: 900;
                color: white;
            }
            .phfSmall1 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 16px;
                color: white;
                font-weight: 900;
                margin-top: 20px;
            }
            .phfSmall2 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 16px;
                color: white;
                font-weight: 900;
            }
        }
        // 底部图片
        .phfBottomImg {
            width: 624px;
            height: 436px;
            margin-left: 250px;
            margin-top: 120px;
            position: relative;
            // display: flex;
            & > div:nth-child(1) {
                transform: scale(1.2);
                position: absolute;
                top: 30px;
                left: 100px;
                z-index: 2;
            }
            & > div:nth-child(2) {
                position: absolute;
                right: 60px;
                top: 60px;
                z-index: 1;
            }
        }
        // 右侧文字
        .phfTextBox1 {
            width: 200px;
            height: 100px;
            position: absolute;
            top: 45%;
            right: 5%;
            color: white;
            text-align: left;
            .phfBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 20px;
                font-weight: 900;
            }
            .phfSmall {
                width: 100%;
                height: 50px;
                line-height: 25px;
                font-size: 16px;
            }
        }
        .phfTextBox2 {
            width: 200px;
            height: 100px;
            position: absolute;
            bottom: 20%;
            right: 5%;
            text-align: left;
            color: white;
            .phfBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 20px;
                font-weight: 900;
            }
            .phfSmall {
                width: 100%;
                height: 50px;
                line-height: 25px;
                font-size: 16px;
            }
        }
    }
}
</style>
