<template>
    <div class="phContentTwo">
        <img src="http://iv.vu818.com/img/bg2 (1).jpg" alt="" class="pctBg" />
        <div class="phtBox">
            <!-- 左边图片 -->
            <div class="phtLeftImg wow animate__animated animate__slideInUp" v-if="anli.id">
                <vy-phone :element="anli"></vy-phone>
            </div>
            <!-- 右边图标列表 -->
            <div class="phtRightBox">
                <!-- 顶部文字 -->
                <div class="phtTop">
                    <p class="phtBig">特色功能体验更友好全面</p>
                    <p class="phtSmall">
                        二维码名片、地图导航、生成APP、产品展示、新闻动态、人才招聘、360全景、一键拨号、一键分享、自定义表单......
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="phtIcon">
                    <ul>
                        <li class="phtLi wow animate__animated animate__bounceInRight">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.1s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg2" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.2s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg3" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.3s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg4" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.4s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg5" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.5s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg6" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.6s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg7" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.7s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg8" />
                        </li>
                        <li class="phtLi wow animate__animated animate__bounceInRight" data-wow-delay="0.8s">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="phtImg9" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VyPhone from "@/util/VyPhone.vue";
export default {
    components: { VyPhone },
    name: "PhContentTwo",
    data() {
        return {
            anli: {},
        };
    },
    props: ["phoneData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    watch: {
        async phoneData(newVal) {
            let { data } = this.phoneData;
            if (data.length > 20) data = data.slice(0, 20);

            let id = data[Math.floor(Math.random() * (data.length - 1))];
            let { data: list } = await axios.get("/api/project/mobilephoneid", { params: { id } });

            this.anli = list[0];
        },
    },
};
</script>

<style lang="less" scoped>
.phContentTwo {
    width: 100%;
    height: 750px;
    position: relative;
    background-color: rgb(95, 99, 97);
    text-align: left;
    .pctBg {
        width: 100%;
        height: 750px;
        position: absolute;
        top: 0;
    }
    .phtBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边图片
        .phtLeftImg {
            position: absolute;
            left: 0px;
            top: 320px;
            & > div {
                transform: scale(1.4);
            }
        }
        // 右边图标列表
        .phtRightBox {
            width: 774px;
            height: 620px;
            float: right;
            padding-top: 70px;
            margin-right: 100px;
            box-sizing: border-box;
            // 顶部文字
            .phtTop {
                width: 80%;
                height: 170px;
                margin-left: 40px;
                .phtBig {
                    width: 100%;
                    height: 77px;
                    line-height: 77px;
                    font-size: 36px;
                    font-weight: 900;
                    text-align: center;
                    color: white;
                }
                .phtSmall {
                    width: 100%;
                    height: 73px;
                    line-height: 35px;
                    font-size: 20px;
                    margin-top: 20px;
                    color: white;
                }
            }
            // 图标列表
            .phtIcon {
                width: 100%;
                height: 250px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    //   justify-content: space-evenly;
                    flex-wrap: wrap;
                    .phtLi {
                        width: 80px;
                        height: 80px;
                        margin-left: 40px;
                        position: relative;
                        overflow: hidden;
                        .phtImg {
                            position: absolute;
                            left: 3px;
                        }
                        .phtImg2 {
                            width: 1600px;
                            position: absolute;
                            left: -79px;
                        }
                        .phtImg3 {
                            width: 1600px;
                            position: absolute;
                            left: -159px;
                        }
                        .phtImg4 {
                            width: 1600px;
                            position: absolute;
                            left: -240px;
                        }
                        .phtImg5 {
                            width: 1600px;
                            position: absolute;
                            left: -320px;
                        }
                        .phtImg6 {
                            width: 1620px;
                            position: absolute;
                            left: -407px;
                        }
                        .phtImg7 {
                            width: 1630px;
                            position: absolute;
                            left: -490px;
                        }
                        .phtImg8 {
                            width: 1600px;
                            position: absolute;
                            left: -560px;
                        }
                        .phtImg9 {
                            width: 1600px;
                            position: absolute;
                            left: -639px;
                        }
                    }
                }
            }
        }
    }
}
</style>
